
.lnb--all-filled ::v-deep {
    line-height: 1.35;
    background-color: transparent;
    border-top: 1px solid var(--v-grey-lighten3);
    border-bottom: 1px solid var(--v-grey-lighten3);
    padding: 32px 0;
    > li {
        > a {
            font-weight: 400;
            color: var(--v-grey-base);
            padding: 8px;
            &:hover {
                font-weight: 700;
                color: var(--v-primary-base);
            }
        }
    }
}
.gnb__item {
    &.active {
        .lnb {
            background-color: var(--v-grey-lighten5);
        }
    }
}
