
// main-visual
.main-visual {
    width: 100%;
    max-width: 1920px;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    &__inner {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 10;
        ::v-deep{
            .swiper-pagination{
                position: relative;
                left: 0;
                bottom: 0;
            }
        }

    }
    &__img {
        max-width: 100%;
        width: 100%;
        height: calc(100vh - var(--header-height));
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: scale(1.2);
        transform-origin: 70% center;
        transition: 5.2s ease-in-out;
    }    
    .swiper-slide-active{
        .main-visual__img{
            transform: scale(1);
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    // main-visual
    .main-visual {
        &__inner{
            padding-top: var(--header-height);
        }
        &__img {
            height: 100vh;
        }
    }
}
