
.header-body--basic {
    background-color: #fff;
    border-bottom: 1px solid var(--border-color);
}
.app--main .header:not(:hover, .scroll-active){
    .header-body--basic {
        background-color: transparent;      
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        ::v-deep{
            .logo a{
                background-image: url(/images/logo-light.svg);
            }
            .gnb__link:not(:hover, .gnb__item.active>.gnb__link){
                color: #fff !important;
            }
        }
    }
}
