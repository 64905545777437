
.user-items {
    ::v-deep {
        font-size: 1.4rem;
        color: var(--v-grey-base);

        > .row {
            > .col {
                line-height: 0;
            }
        }
        .v-btn--text.v-btn--text-fit {
            padding: 0 !important;
        }

        .v-divider {
            border-color: var(--v-grey-lighten3);
            &--vertical {
                height: 12px;
                margin-left: -1px;
            }
        }
    }

    &--light {
        .v-btn {
            color: #fff !important;
        }
    }
}
.v-application {
    .user-items--light {
        .v-divider {
            border-color: rgba(255, 255, 255, 0.2) !important;
            background-color: rgba(255, 255, 255, 0.2) !important;
        }
    }
}
