
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
}
.icon--size {
    &-xx-small {
        width: 8px;
    }
    &-x-small {
        width: 10px;
    }
    &-small {
        width: 12px;
    }
    &-default {
        width: 14px;
    }
    &-large {
        width: 16px;
    }
    &-x-large {
        width: 20px;
    }
    &-xx-large {
        width: 24px;
    }
}
