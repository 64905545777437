
.v-tab--dot {
    position: relative;
    background-color: transparent !important;
    &::after {
        content: "";
        position: absolute;
        top: auto;
        right: auto;
        bottom: -4px;
        left: 50%;
        transform: translate(-50%, 0);
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: var(--tab-active-color) !important;
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
        opacity: 0;
    }
    &.v-tab {
        &--active {
            color: var(--tab-active-color) !important;
            background-color: transparent !important;
            &::after {
                opacity: 1;
            }
        }
    }
}
