
.swiper-pagination {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    color: var(--v-primary-base);
    &--sm {
        ::v-deep {
            .swiper-pagination {
                &-bullet {
                    width: 8px;
                    height: 8px;
                    margin: 0 4px;
                }
            }
        }
    }

    // theme
    &--light {
        color: #fff !important;
    }
    &--dark {
        color: var(--v-grey-darken4) !important;
    }
    &--angular {
        ::v-deep {
            .swiper-pagination {
                &-bullet {
                    &,
                    &-active,
                    &::before,
                    &::after {
                        border-radius: 0 !important;
                    }
                }
            }
        }
    }

    // align
    &--left {
        left: var(--container-gutter);
        transform: translate(0, 0);
    }
    &--right {
        left: auto;
        right: var(--container-gutter);
        transform: translate(0, 0);
    }
}
::v-deep {
    .swiper-pagination {
        &-bullets {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-bullet {
            position: relative;
            display: block;
            width: 10px;
            height: 10px;
            margin: 0 6px;
            border-radius: 100%;
            opacity: 0.2;
            background-color: currentColor;
            border: 1px solid currentColor;
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &::before,
            &::after {
                background-color: currentColor;
                -webkit-transition: all ease-out 0.2s;
                -ms-transition: all ease-out 0.2s;
                transition: all ease-out 0.2s;
            }
            &-active {
                opacity: 1;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .swiper-pagination {
        bottom: 50px;
    }
    ::v-deep {
        .swiper-pagination {
            &-bullet {
                width: 12px;
                height: 12px;
                margin: 0 8px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .swiper-pagination {
        bottom: 80px;
    }
}
@media (min-width: 1200px) {
}
