
.footer-body--basic {
    padding: 30px 0;
    border-top: 1px solid var(--border-color-light);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-body--basic {
        padding: 60px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
