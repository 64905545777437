
.lnb--topline-border ::v-deep {
    top: calc(var(--header-body-height) - 4px);
    border-top: 4px solid var(--v-primary-base);
    > li {
        border: 1px solid var(--border-color);
        border-top: 0;
        > a {
            &:hover {
                color: var(--v-primary-base);
            }
        }
    }
}
